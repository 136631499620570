<template>
  <div class="demo-list">
    <div class="box-search mb-5">
      <div class="row">
        <div class="col-12 position-relative">
          <div class="border rounded p-1 pr-2">
            <span class="text-left">検索条件</span>
          </div>
          <v-list class="pt-0">
            <v-list-group :value="true" class="nav-bar-group" @click="() => { this.rotateNavBar = !this.rotateNavBar }">
              <template #activator>
                <i class="fa fa-bars text-primary float-right pt-1 position-absolute" :class="[rotateNavBar ? 'nav-bar-rotate' : 'nav-bar-rollback']"></i>
              </template>
              <v-list-item>
                <div class="border border-top-0 rounded p-1 pr-2">
                  <div class="col-12 p-0 m-0 text-right">
                    <button @click="clearAllSearch" type="button" class="btn btn-grey">
                      <i class="fa fa-trash" aria-hidden="true"></i>
                      クリア
                    </button>
                  </div>
                  <div class="row">
                    <div class="col-2">
                      氏名
                      <input type="text" class="form-control" placeholder="氏名 or 氏名カナ" v-model="dataSearch.patient_name"
                             @blur="validate('patient_name')" @keyup="validate('patient_name')" />
                      <span class="text-danger">{{ errors.patient_name }}</span>
                    </div>
                    <div class="col-2">
                      利用券整理番号
                      <input v-model="dataSearch.patient_ticket" class="form-control" placeholder=""
                             @blur="validate('patient_ticket')" @keyup="validate('patient_ticket')" />
                      <span class="text-danger">{{ errors.patient_ticket }}</span>
                    </div>
                    <div class="col-2">
                      電話番号
                      <input v-model="dataSearch.patient_phone" class="form-control" placeholder="ハイフンなし"
                             @blur="validate('patient_phone')" @keyup="validate('patient_phone')" />
                      <span class="text-danger">{{ errors.patient_phone }}</span>
                    </div>
                    <div class="col-2">
                      保険者
                      <select v-model="dataSearch.insurer" class="form-select">
                        <option :value="null"></option>
                        <option :value="insurer.id" v-for="insurer in insurerArr" :key="insurer.id + 'insurer'">
                          [{{ insurer.client_organ_code }}]
                          {{ insurer.client_organ_name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-2">
                      保健指導区分
                      <select v-model="dataSearch.health_guidance_div" class="form-select">
                        <option :value="null"></option>
                        <option :value="code.value" v-for="code in codeArr" :key="code.text + '1'">{{ code.text }}</option>
                      </select>
                    </div>
                    <div class="col-2">
                      受付状況
                      <select v-model="dataSearch.health_guide" class="form-select">
                        <option :value="null"></option>
                        <option :value="getCodeReception.value" v-show="getCodeReception.value != 30"
                                v-for="getCodeReception in getCodeReceptionArr" :key="getCodeReception.text + 'getCodeReception'">
                          {{ getCodeReception.text }}
                        </option>
                      </select>
                    </div>
                    <div class="col-6">
                      未受付条件
                      <hr class="m-0" />
                      <div class="row">
                        <div class="col-8">
                          取込日時
                          <div class="row align-items-center">
                            <div class="col-5 mt-0 pt-0">
                              <date-picker autocomplete="off" v-model="dataSearch.from_date" id="from_date" name="from_date" :config="options"
                                           @input="validate('to_date')"></date-picker>
                            </div>
                            <div class="col-1 text-center pt-0">-</div>
                            <div class="col-5 mt-0 pt-0">
                              <date-picker autocomplete="off" v-model="dataSearch.to_date" id="to_date" name="to_date" :config="options"
                                           @input="validate('to_date')"></date-picker>
                            </div>
                            <span class="text-danger">{{ errors.to_date }}</span>
                          </div>
                        </div>
                        <div class="mt-8 col-4 form-check">
                          <label class="form-check-label" for="exampleCheck1">保健指導対象外のみ</label>
                          <input v-model="dataSearch.health_guidance_no_target" type="checkbox" class="form-check-input"
                                 id="exampleCheck1" />
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      受付済条件
                      <hr class="m-0" />
                      <div class="row">
                        <div class="col-2">
                          <div>
                            受付年度
                          </div>
                          <div class="row">
                            <div class="col pr-0 py-0">
                              <date-picker autocomplete="off" v-model="dataSearch.year_reception" id="year_reception" name="year_reception"
                                       :config="year_reception"></date-picker>
                            </div>
                            <div class="col-auto px-1 pt-2 pb-0">
                              以降
                            </div>
                          </div>
                        </div>
                        <div class="col-10">
                          支援状況
                          <div class="row">
                            <div v-for="item in dataSupportStatus" :key="item.value + 'dataSupportStatus'"
                                 class="col-auto ml-3 form-check">
                              <label class="form-check-label" :for="item.value + 'dataSupportStatus'">{{
                                item.text
                                }}</label>
                              <input type="checkbox" checked class="form-check-input value_health_guide_status"
                                     :value="item.value" :id="item.value + 'dataSupportStatus'" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 text-right">
                      <button @click="getList(); currentPage = 1" type="button" class="btn btn-success mt-5">
                        <i class="fa fa-search" aria-hidden="true"></i>
                        検索
                      </button>
                    </div>
                  </div>
                </div>
              </v-list-item>
            </v-list-group>
          </v-list>
        </div>
      </div>
    </div>
    <div class="data-table-container">
      <table class="data-table table table-responsive table-hover" id="tableHighlight">
        <thead class="sticky-top bg-white">
          <tr>
            <th class="text-nowrap">受付</th>
            <th class="text-nowrap">支援</th>
            <th class="text-nowrap">支援状況</th>
            <th class="text-nowrap">対象者ID</th>
            <th class="text-nowrap">氏名(カナ)</th>
            <th class="text-nowrap">生年月日/性別</th>
            <th class="text-nowrap">利用券整理番号</th>
            <th class="text-nowrap">保健指導区分</th>
            <th class="text-nowrap">保険者</th>
            <th class="text-nowrap">保険証 番号/記号/枝番</th>
            <th class="text-nowrap">取込日時</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in lists" :key="item.id + 'key' + index" v-bind:id="'highlight' + item.id"
            @click="changeCellHighlight(item.id)" :class="cellChangeHighlight">
            <td class="text-nowrap">
              <span v-if="!item.health_guidance_no_target" class="text-link"
                @click="userDetail(item.id, item.health_guidance_reception_id, 'Receipt')">
                {{ item.reception_date ? item.reception_date : '未' }}
              </span>
            </td>
            <td class="text-nowrap">
              <button class="btn btn-primary pb-0 pt-0" type="button" v-if="item.reception_date"
                @click="onHandleClickDashboardSupport(item.health_guidance_reception_id)">
                {{ item.contract_fiscal_year }}
                年度支援
                <span v-if="item.health_guidance_period">
                  (第{{ item.health_guidance_period }}期)
                </span>
              </button>
            </td>
            <td class="text-nowrap">
              {{ getStatusSupport(item) }}
            </td>
            <td class="text-nowrap">
              <span class="text-link" @click="userDetail(item.id, null, 'Detail')">
                {{ String(item.id).padStart(10, "0") }}
              </span>
            </td>
            <td class="text-nowrap">{{ item.patient_name_kana }}</td>
            <td class="text-nowrap">{{ item.birthday }} / {{ item.gender }}</td>
            <td class="text-nowrap">{{ item.use_ticket_ref_number }}</td>
            <td class="text-nowrap">{{ item.health_guidance_div }}</td>
            <td class="text-nowrap">
              [{{ item.client_organ_code }}] {{ item.client_organ_name }}
            </td>
            <td class="text-nowrap">
              {{
			    item.insured_card_number !== null &&
			    item.insured_card_number !== undefined &&
			    item.insured_card_number !== 0 &&
			    item.insured_card_number !== '' ? item.insured_card_number : 'ー'
	          }}
	          /
	          {{
		        item.insured_card_symbol !== null &&
                item.insured_card_symbol !== undefined &&
                item.insured_card_symbol !== 0 &&
                item.insured_card_symbol !== '' ? item.insured_card_symbol : 'ー'
	          }}
	          /
	          {{
		        item.insured_card_branch_code !== null &&
                item.insured_card_branch_code !== undefined &&
                item.insured_card_branch_code !== 0 &&
                item.insured_card_branch_code !== '' ? item.insured_card_branch_code : 'ー'
	          }}
            </td>
            <td class="text-nowrap">
              {{ item.healthexam_result_import_datetime }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <Pagination :total-page="totalPage" :current-page="currentPage" @onHandleChangePage="onHandleChangePage" />
    <slide>
      <Detail v-if="component === 'Detail'" :isShowNavigation="randomString" :dataDetailId="idSelected" :checkViewOrEdit="true" @reload="reload">
      </Detail>
      <ReceiptMedical v-if="component === 'Receipt'" :isShowNavigation="randomString" :dataDetailId="idSelected" :dataReceptionsId="dataReceptionsId"
        :checkViewOrEdit="true" @reload="reload" />
    </slide>
  </div>
</template>
<script>
import Slide from "@/components/Slide";
import { MESSAGE } from "@/common/constants";
import Pagination from "@/components/common/Pagination";
import MedicalDesignatedSubjects from "@/services/api/MedicalDesignatedSubjects";
import { mapMutations, mapGetters } from "vuex";
import { INTERNAL_SERVER_ERROR, SUCCESS} from "@/constants";
import { CODE_SUPPORT_STATUS } from "@/common/constants";
import Detail from "@/components/DetailImportDiagnosticData";
import moment from "moment";
import ReceiptMedical from "../../components/ReceiptMedicalDirectives/Modal/ReceiptMedical";
import { formSearchMedicalDesignatedSubjects } from "../../common/schemaValidation";
import CodeDefineRepository from "@/services/api/CodeDefineRepository";
import { CODE_GENDER, CODE_REASON_TERMINATE, TOAST_TIME_OUT } from "@/common/constants";
import mixins from "@/mixins";
import _ from "lodash";

export default {
    name: "list",
    mixins: [mixins],
    components: {
        ReceiptMedical,
        Slide,
        Detail,
        Pagination
    },
    data() {
        return {
            component: undefined,
            lists: [],
            messages: MESSAGE,
            totalPage: 1,
            currentPage: 1,
            perPage: 150,
            idSelected: undefined,
            dataReceptionsId: null,
            dataSearch: {
                health_guidance_no_target: false,
                patient_name: null,
                patient_phone: null,
                health_guidance_div: null,
                patient_ticket: null,
                insurer: null,
                health_guide: null,
                from_date: moment(new Date()).format("YYYY-MM-DD 00:00:00"),
                to_date: moment(new Date()).format("YYYY-MM-DD 23:59:59"),
                year_reception: String(this.getFiscalYear()),
                health_guide_status: [],
                limit: 150,
            },
            errors: {
                patient_patient_name: "",
                patient_phone: "",
                patient_ticket: "",
                from_date: "",
                to_date: ""
            },
            dataSupportStatus: [],
            codeArr: [],
            insurerArr: [],
            getCodeReceptionArr: [],
            code_gender: [],
            code_reason_terminate: [],
            cellChangeHighlight: null,
            rotateNavBar: false,
            randomString: "",
        };
    },
    computed: {
        ...mapGetters({
            pagePrevious: "dashboard/getPagePrevious"
        }),
        options() {
            return {
                format: "YYYY-MM-DD HH:mm:ss",
                useCurrent: false,
                showClear: true,
                showClose: true,
                icons: {
                    time: "far fa-clock",
                    date: "far fa-calendar",
                    up: "fas fa-arrow-up",
                    down: "fas fa-arrow-down",
                    previous: "fas fa-chevron-left",
                    next: "fas fa-chevron-right",
                    today: "fas fa-calendar-check",
                    clear: "far fa-trash-alt",
                    close: "far fa-times-circle",
                },
                locale: moment.locale("ja"),
            };
        },
        year_reception() {
            return {
                format: "YYYY",
                useCurrent: false,
                showClear: true,
                showClose: true,
                icons: {
                    time: "far fa-clock",
                    date: "far fa-calendar",
                    up: "fas fa-arrow-up",
                    down: "fas fa-arrow-down",
                    previous: "fas fa-chevron-left",
                    next: "fas fa-chevron-right",
                    today: "fas fa-calendar-check",
                    clear: "far fa-trash-alt",
                    close: "far fa-times-circle",
                },
                locale: moment.locale("ja"),
            };
        },
    },
    async created() {
        this.code_gender = await this.getCodeDefine(CODE_GENDER, "acronym");

        this.code_reason_terminate = await this.getCodeDefine(CODE_REASON_TERMINATE);

        await CodeDefineRepository.get(CODE_SUPPORT_STATUS).then((res) => {
	        if(res.errorCode === INTERNAL_SERVER_ERROR && !res.error?.code) {
		        this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		        return;
	        }
            this.dataSupportStatus = [];
            this.dataSearch.health_guide_status = [];
            for (const [key, value] of Object.entries(res.data)) {
                this.dataSupportStatus.push({
                    text: value,
                    value: key
                });
                this.dataSearch.health_guide_status.push(key)
            }
        });

        await MedicalDesignatedSubjects.getMedicalIndication().then((res) => {
	        if(res.errorCode === INTERNAL_SERVER_ERROR && !res.error?.code) {
		        this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		        return;
	        }
            this.codeArr = [];
            for (const [key, value] of Object.entries(res.data)) {
                this.codeArr.push({
                    text: value,
                    value: key
                });
            }
        });

        await MedicalDesignatedSubjects.getInsurer().then((res) => {
	        if(res.errorCode === INTERNAL_SERVER_ERROR && !res.error?.code) {
		        this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		        return;
	        }
            this.insurerArr = res.data.data;
        });

        await MedicalDesignatedSubjects.getCodeReception().then((res) => {
	        if(res.errorCode === INTERNAL_SERVER_ERROR && !res.error?.code) {
		        this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		        return;
	        }
            this.getCodeReceptionArr = [];
            for (const [key, value] of Object.entries(res.data)) {
                this.getCodeReceptionArr.push({
                    text: value,
                    value: key
                });
            }
        });

        if (this.pagePrevious != undefined) {
            this.dataSearch = this.pagePrevious.formSearch;
            this.currentPage = this.pagePrevious.currentPage;
            this.perPage = this.pagePrevious.perPage;
            let checkData = document.getElementsByClassName("value_health_guide_status");
            for (let i = 0; i < checkData.length; i++) {
                if (this.pagePrevious.formSearch.health_guide_status.filter(x => x == checkData[i].value).length < 1) {
                    checkData[i].checked = false
                }
            }
            await this.getList();
        }

        $(document).keypress((e) => {
            if (e.which === 13) {
                this.getList()
            }
        });
    },
    methods: {
        ...mapMutations({
            setIsLoading: "common/setIsLoading",
            setSegment: "dashboard/setSegment",
            setReceptionId: "dashboard/setReceptionId",
            setPagePrevious: "dashboard/setPagePrevious",
        }),
        validate(field) {
            formSearchMedicalDesignatedSubjects
                .validateAt(field, this.dataSearch)
                .then(() => {
                    this.errors[field] = "";
                })
                .catch((err) => {
                    this.errors[field] = err.message;
                });
        },
        reload(data, action) {
            this.idSelected = null;
            this.dataReceptionsId = null;
            if (action == "update" || action == "delete") {
                this.getList();
            }
        },
        getStatusSupport(item) {
            if (item.health_guidance_no_target == 1) {
                return "保健指導対象外";
            } else if (!item.reception_date) {
                return "未受付";
            }
            switch (parseInt(item.support_flow_status)) {
            case 10:
                return "支援開始前";
            case 20:
                return "支援実施中";
            case 30:
                return "支援完了";
            case 40:
                let support_stop_reason = item.support_stop_reason ? this.code_reason_terminate[parseInt(item.support_stop_reason)] : null;
                return `途中終了(${support_stop_reason})`;
            case 50:
                return "初回面接辞退";
            default:
                return null;
            }
        },
        changeCellHighlight(id) {
            $("#tableHighlight").find(".cellHighlight").removeClass("cellHighlight");
            $("#highlight" + id).addClass("cellHighlight");
        },
        clearAllSearch() {
            let value_health_guide_status = document.getElementsByClassName("value_health_guide_status");
            for (let i = 0; i < value_health_guide_status.length; i++) {
                value_health_guide_status[i].checked = true
            }
            this.dataSearch = {
                health_guidance_no_target: false,
                patient_name: null,
                patient_phone: null,
                health_guidance_div: null,
                patient_ticket: null,
                insurer: null,
                health_guide: null,
                from_date: moment(new Date()).format("YYYY-MM-DD 00:00:00"),
                to_date: moment(new Date()).format("YYYY-MM-DD 23:59:59"),
                year_reception: String(this.getFiscalYear()),
                health_guide_status: [],
            };
            this.getList();
        },
        getList() {
            formSearchMedicalDesignatedSubjects
                .validate(this.dataSearch, { abortEarly: false })
                .then(() => {
                    this.errors = {
                        patient_patient_name: "",
                        patient_phone: "",
                        patient_ticket: "",
                        from_date: "",
                        to_date: ""
                    };
                    this.setIsLoading(true);
                    this.dataSearch.from_date = moment(this.dataSearch.from_date).format("YYYY-MM-DD HH:mm:ss");
                    this.dataSearch.to_date = moment(this.dataSearch.to_date).format("YYYY-MM-DD HH:mm:ss");

                    let value_health_guide_status = document.getElementsByClassName("value_health_guide_status");
                    if (value_health_guide_status.length > 0) {
                        this.dataSearch.health_guide_status = [];
                        for (let i = 0; i < value_health_guide_status.length; i++) {
                            if (value_health_guide_status[i].checked == true) {
                                this.dataSearch.health_guide_status.push(value_health_guide_status[i].value);
                            }
                        }
                    }
                    MedicalDesignatedSubjects.getList(this.dataSearch, this.currentPage, this.perPage).then((res) => {
                        window.scrollTo({
                            behavior: "smooth",
                            top: 0,
                        });
                        const { data, status, error } = res;

                        this.setIsLoading(false);
                        if (status !== SUCCESS) {
	                        this.$toast.error(`[${error?.messageID}] ${error?.message}`);
                            return;
                        }
                        this.lists = [];
                        data.data.map((x) => {
                            this.lists.push(
                                {
                                    health_guidance_reception_id: x.health_guidance_reception_id,
                                    reception_date: x.reception_date,
                                    contract_fiscal_year: x.contract_fiscal_year ? String(x.contract_fiscal_year).slice(-2) : null,
                                    health_guidance_period: x.health_guidance_period,
                                    health_guidance_no_target: x.health_guidance_no_target,
                                    support_flow_status: x.support_flow_status,
                                    support_stop_reason: x.support_stop_reason,
                                    id: x.id,
                                    patient_name_kana: x.patient_name_kana,
                                    birthday: x.patient_birthday,
                                    gender: this.code_gender[x.patient_gender],
                                    use_ticket_ref_number: x.use_ticket_ref_number,
                                    health_guidance_div: this.getHealthGuidanceDiv(x.health_guidance_div),
                                    client_organ_code: x.client_organ_code,
                                    client_organ_name: x.client_organ_name,
                                    insured_card_number: x.insured_card_number,
                                    insured_card_symbol: x.insured_card_symbol,
                                    insured_card_branch_code: x.insured_card_branch_code,
                                    healthexam_result_import_datetime: x.healthexam_result_import_datetime,
                                }
                            );
                        });
                        $(".demo-list").closest(".container-fluid").css("height", this.lists.length ? "100%" : "auto");

                        $("#tableHighlight").find(".cellHighlight").removeClass("cellHighlight");
                        this.totalPage = data.last_page;
                        this.currentPage = data.current_page;

                        if (this.lists.length < 1) {
                            this.notifyNoSearchResults();
                        }
                    });
                })
                .catch((err) => {
                    this.setIsLoading(false);
                    err.inner.forEach((error) => {
                        this.errors[error.path] = error.message;
                    });
                });
        },
        getHealthGuidanceDiv(code) {
            const value = this.codeArr.filter((x) => x.value == code);
            if (value.length > 0) {
                return value[0].text;
            }
            return null;
        },
        onHandleChangePage(pageNumber) {
            this.currentPage = pageNumber;
            this.getList();
        },
        onHandleClickDashboardSupport(receptionId) {
            this.setReceptionId(receptionId)
            this.$store.commit("dashboard/setSegment", null);
            this.$store.commit("dashboard/setSegmentBlockL", null);

            const data = {
                formSearch: this.dataSearch,
                page: "medical-designated-subjects-list",
                currentPage: this.currentPage,
                perPage: this.perPage
            }
            this.setPagePrevious(data);

            this.$router.push({
                path: `/dashboard?reception_id=${receptionId}`
            })
        },
        userDetail(id, health_guidance_reception_id, type) {
            this.idSelected = id;
            this.dataReceptionsId = health_guidance_reception_id;
            this.component = type;
            this.randomString = (Math.random() + 1).toString(36).substring(7);
        }
    },
    destroyed() {
        $(document).unbind()
    }
};
</script>
<style lang="sass" scoped>
  @import "../../styles/common"
  .cellHighlight
    background-color: #d2e3fb
  .table>thead
    z-index: auto
</style>
